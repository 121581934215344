<template>
  <div id="cookiebar" v-if="!cookieAvailable">
    <div class="inner">
      <div class="text">
        <h3>Cookies</h3>
        <p>
          De websites van Matchis maken gebruik van cookies om de
          functionaliteit te waarborgen en gebruikerservaring te verbeteren.
          Meer informatie vind je op onze
          <a href="https://www.matchis.nl/cookies/" target="_blank"
            >cookie-pagina</a
          >.
        </p>
      </div>
      <div class="btns">
        <button
          id="no"
          href="#CookieDisallowed"
          class="cookiebtn cookiebtn__secondary"
          v-on:click="declineCookie"
        >
          Alleen functionele cookies
        </button>
        <button
          id="ok"
          href="#CookieAllowed"
          class="cookiebtn cookiebtn__primary"
          v-on:click="acceptCookie"
        >
          Accepteer alle cookies
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import cookies from "vue-cookies";

export default {
  name: "app-cookie",
  data() {
    return {
      cookieAvailable: false,
    };
  },
  created() {
    // If we have a cookie then dont show the cookiebar
    if (cookies.get("cookiebar")) {
      this.cookieAvailable = true;
      document.body.classList.remove("no-scroll");
    }
    if (cookies.get("cookiebar") === null) {
      document.body.classList.add("no-scroll");
    }
  },
  methods: {
    acceptCookie(event) {
      event.preventDefault();
      this.cookieAvailable = true;
      // Set a cookie if not set already with cookies allowed
      cookies.set(
        "cookiebar",
        "CookieAllowed",
        "1y",
        "/",
        process.env.VUE_APP_COOKIE_DOMAIN,
        true,
        "none"
      );
      // Enable GA and GTM tags
      //   this.$gtm.enable(true);
      location.reload();
    },

    declineCookie(event) {
      event.preventDefault();
      this.cookieAvailable = true;
      // Set a cookie if not set already with cookies allowed
      cookies.set(
        "cookiebar",
        "CookieDisallowed",
        "1y",
        "/",
        process.env.VUE_APP_COOKIE_DOMAIN,
        true,
        "none"
      );
      // Enable GA and GTM tags
      //   this.$gtm.enable(false);
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
#cookiebar {
  background: rgba(0, 0, 0, 0.325);
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .inner {
    display: flex;
    width: 39.125rem;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem;
    border-radius: 5px;
    gap: 30px;

    font-weight: 500;
    @media screen and (max-width: 760px) {
      padding: 1.875rem;
    }
  }
  h3 {
    text-transform: uppercase;
    font-size: 24px;
    line-height: normal;
    font-weight: 600;
    color: #2f2933;
    font-family: "Source Sans Pro", sans-serif;
    margin-bottom: 10px;
  }
  .btns {
    display: flex;
    flex-direction: row;
    gap: 25px;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: fit-content;

    @media screen and (max-width: 760px) {
      width: 100%;
    }
  }
  .cookiebtn {
    text-decoration: none;
    background: transparent;
    border: none;
    cursor: pointer;
    @media screen and (max-width: 760px) {
      width: 100%;
    }
    &__primary {
      font-weight: bold;
      background: #50ca7c;
      color: white;
      padding: 13px 40px;
      border-radius: 6px;
      font-weight: 500;
      &:hover {
        background: darken(#50ca7c, 10%);
        color: white;
      }
    }

    &__secondary {
      font-weight: normal;
      color: #666666;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  p {
    font-size: 1em;
    margin: 0;
    color: #666666;
    font-weight: normal;
    line-height: 22px;

    a {
      color: #008abb;
      text-decoration: underline;

      &:hover {
        color: #232f62;
      }
    }
  }
}
</style>
