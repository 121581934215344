import { createWebHistory, createRouter } from 'vue-router'
import store from './store'

// Form layout
import Form from './components/views/Form.vue'

// Form pages
import Home from './components/pages/Home.vue'
import Data from './components/pages/Data.vue'
import Payment from './components/pages/Payment.vue'
import Thanks from './components/pages/Thanks.vue'
import RegThanks from './components/pages/RegThanks.vue'
import Failed from './components/pages/Failed.vue'
import QR from './components/pages/QR.vue'


const routes = [
  {
    name: 'form',
    path: '/',
    component: Form,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: (to, from, next) => {
          store.commit('app/setReferer', 'home')
          next()
        },
      },
      {
        path: '/registratie',
        name: 'Registratie',
        component: Home,
        beforeEnter: (to, from, next) => {
          store.commit('app/setReferer', 'registratie')
          next()
        },
      },
      {
        path: '/gegevens',
        name: 'Data',
        component: Data,
      },
      {
        path: '/betaling',
        name: 'Payment',
        component: Payment,
      },
      {
        path: '/bedankt',
        name: 'Thanks',
        component: Thanks,
        beforeEnter: (to, from, next) => {
          if (store.getters['app/getReferer'] == 'registratie') next({path: '/registratie/bedankt', query: to.query})
          next()
        },
      },
      {
        path: '/registratie/bedankt',
        name: 'RegThanks',
        component: RegThanks,
      },
      {
        path: '/mislukt',
        name: 'Failed',
        component: Failed,
      },
      {
        path: '/share',
        name: 'Share',
        component: QR,
      },
    ],
  },
  // Catch all redirect
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // if (to.redirectedFrom && to.redirectedFrom.fullPath == "/registratie") store.commit('app/setReferer', 'registratie')
  if (to.name === 'Data' && !store.state.form.donation.amount)
    next({ name: 'Home' })
  if (to.name === 'Payment' && !store.state.form.data.email)
    next({ name: 'Data' })
  next()
})

export default router
