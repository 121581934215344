<template>
  <div class="data">
    <router-link
      v-if="referer == 'registratie'"
      class="back-btn"
      :to="{ name: 'Registratie' }"
      tag="li"
    >
      <i class="far fa-arrow-left"></i><span>Terug</span>
    </router-link>
    <router-link v-else class="back-btn" :to="{ name: 'Home' }" tag="li">
      <i class="far fa-arrow-left"></i><span>Terug</span>
    </router-link>
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
      <div class="form-group" :class="{ error: errors }">
        <legend>Gegevens</legend>
      </div>
      <div class="form-group labelinputs" :class="{ error: errors.Firstname }">
        <Field
          name="firstname"
          type="text"
          v-model="firstname"
          placeholder="Voornaam"
        />
        <label for="firstname">Voornaam</label>
        <span class="error">{{ errors.firstname }}</span>
      </div>
      <div class="form-group labelinputs" :class="{ error: errors.Lastname }">
        <Field
          name="lastname"
          type="text"
          v-model="lastname"
          placeholder="Achternaam"
        />
        <label for="lastname">Achternaam</label>
        <span class="error">{{ errors.lastname }}</span>
      </div>
      <div class="form-group labelinputs" :class="{ error: errors.Email }">
        <Field
          name="email"
          type="email"
          v-model="email"
          placeholder="E-mailadres"
        />
        <label for="email">E-mailadres</label>
        <span class="error">{{ errors.email }}</span>
      </div>
      <div
        class="form-group postalNumbers labelinputs"
        :class="{ error: errors.Address }"
        @change="fetchAddress"
      >
        <legend class="small-legend">Postcode (optioneel)</legend>
        <div class="field-wrapper">
          <Field
            name="postalNumbers"
            type="text"
            maxlength="4"
            v-model="postalNumbers"
            placeholder="1234"
            :rules="{ digits: 3 }"
          />
          <label for="postalNumbers"></label>
          <span class="error"></span>
        </div>
        <div class="field-wrapper">
          <Field
            name="postalLetters"
            type="text"
            maxlength="2"
            v-model="postalLetters"
            placeholder="AB"
          />
          <label for="postalLetters"></label>
          <span class="error"></span>
        </div>
        <div class="field-wrapper">
          <Field
            name="houseNumber"
            type="text"
            v-model="houseNumber"
            placeholder="Huisnr."
          />
          <label for="houseNumber"></label>
          <span class="error"></span>
        </div>
        <span class="error">{{ errors.Address }}</span>
      </div>
      <div class="form-group">
        <Field
          :class="{ 'grey-input': postalAuto }"
          name="street"
          v-model="street"
          type="text"
          placeholder="Straatnaam"
          :disabled="postalAuto"
        />
      </div>
      <div class="form-group">
        <Field
          :class="{ 'grey-input': postalAuto }"
          name="city"
          v-model="city"
          type="email"
          placeholder="Plaats"
          :disabled="postalAuto"
        />
      </div>
      <div :class="{ hidden: !postalSend, 'postal-link': true }">
        <a href="#" @click.prevent="postalAuto = false"
          >Klopt dit niet? Klik hier om het handmatig in te vullen.</a
        >
      </div>
      <div class="form-group checkboxinput" :class="{ error: errors.Policy }">
        <Field v-slot="{ field }" name="policy" type="checkbox" :value="false">
          <div class="form-checkbok">
            <label>
              <input
                class="checkbox"
                type="checkbox"
                name="policy"
                v-bind="field"
                :value="false"
              />
              <span
                >Ik geef toestemming aan Matchis Fonds stamceldonatie om mijn
                persoonsgegevens te mogen verwerken. Wij verwerken deze met de
                groots mogelijke zorgvuldigheid, voor verdere informatie
                verwijzen wij jou naar
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://fondsstamceldonatie.nl/privacybeleid-matchis-fonds-stamceldonatie"
                  >ons privacy beleid</a
                >.</span
              >
            </label>
          </div>
          <span class="error">{{ errors.policy }}</span>
        </Field>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary mr-1">
          Naar betaling
        </button>
      </div>
    </Form>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { mapState } from "vuex";
import vuexMixin from "../../mixins/vuexMixin";

export default {
  name: "Data",
  mixins: [vuexMixin],
  components: {
    Form,
    Field,
  },
  data() {
    return {
      street: null,
      place: null,
      postalSend: false,
      postalAuto: true,
    };
  },
  created() {
    vuexMixin.methods.fillDataFromStore(this, this.data);
    this.fetchAddress();
  },
  setup() {
    function emptyStringToNull(value, originalValue) {
      if (typeof originalValue === "string" && originalValue === "") {
        return null;
      }
      return value;
    }

    const schema = Yup.object().shape({
      firstname: Yup.string().required("Voornaam is verplicht"),
      lastname: Yup.string().required("Voornaam is verplicht"),
      email: Yup.string()
        .email("Vul een geldig emailadres in")
        .required("Email is verplicht"),
      postalNumbers: Yup.number()
        .positive()
        .integer()
        .min(1000)
        .max(9999)
        .transform(emptyStringToNull)
        .nullable(),
      postalLetters: Yup.string().when("postalNumbers", {
        is: (val) => val > 1, // alternatively: (val) => val == true
        then: Yup.string()
          .uppercase()
          .matches(/^[A-Za-z]+$/)
          .min(2)
          .max(2),
      }),
      houseNumber: Yup.string().when("postalLetters", {
        is: (val) => (val ? val.length > 0 : false), // alternatively: (val) => val == true
        then: Yup.string().required(),
      }),
      policy: Yup.boolean()
        .test(
          "is-false",
          "Het beleid moet geaccepteerd zijn",
          (value) => value === false
        )
        .required("Het beleid moet geaccepteerd zijn"),
    });
    return {
      schema,
    };
  },
  methods: {
    onSubmit: function (values) {
      this.$store.commit("form/setData", values);
      this.$router.push({ name: "Payment" });
    },

    fetchAddress: function () {
      if (this.postalNumbers && this.postalLetters && this.houseNumber) {
        this.postalSend = true;
        // let address = this.postalNumbers + this.postalLetters + "+" + this.houseNumber;
        this.$store.commit("form/setPostal", {
          postalNumbers: this.postalNumbers,
          postalLetters: this.postalLetters,
          houseNumber: this.houseNumber,
        });
        this.$store
          .dispatch("form/fetchAddress")
          .then((response) => {
            this.street = response.street;
            this.city = response.city;
          })
          .catch((error) => {
            console.info(error);
          });
      }
    },
  },
  computed: {
    ...mapState("form", ["data"]),
    ...mapState("app", ["referer"]),
  },
};
</script>

<style lang="scss" scoped>
.form {
  .form-group {
    &.postalNumbers {
      display: flex;
      label {
        flex: 0 0 100%;
      }
      .field-wrapper {
        margin-right: 20px;
        flex: 0 0 calc(32.5% - 20px);

        @media only screen and (max-width: 992px) {
          margin-right: 10px;
          flex: 0 0 calc(32.5% - 10px);
        }

        &:first-of-type {
          flex: 0 0 35%;
        }
        &:last-of-type {
          margin-right: 0px;
        }
      }
    }
  }
  .postal-link {
    margin-top: 0px;
    margin-bottom: 20px;
    a {
      color: #232f62;
    }
  }
  .small-legend {
    font-size: 19px;
    color: #232f62;
    letter-spacing: 0;
    line-height: 30px;
  }
  label {
    font-size: 19px;
    color: #232f62;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 10px;
  }
  input[type="text"],
  input[type="email"] {
    margin-bottom: 20px;
    &.grey-input {
      background: #f2f3f6;
      border-radius: 6px;
      border: 0px;
      box-shadow: none;
      color: #697286;
    }
  }
  button {
    margin-top: 10px;
  }
}
</style>

<style lang="scss">
$checkbox-size: 20px;
$checkbox-border: #232f62;
$checkbox-selected: #008ab8; // Primary colour

.form {
  .form-group {
    &.checkboxinput {
      .checkbox {
        position: absolute;
        opacity: 0;

        // Text
        & + span {
          display: inline-block;
          position: relative;
          cursor: pointer;
          padding-left: $checkbox-size + 10;
          font-size: 16px;
          line-height: 24px;

          a {
            color: #008ab8;
            text-decoration: underline;
          }

          // Checkbox
          &:before {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            content: "";
            background: transparent;
            width: $checkbox-size;
            height: $checkbox-size;

            box-shadow: inset 0 0 0 1px $checkbox-border;
            border-radius: 4px;
            transition: 200ms ease-in-out all;
          }

          // Tick
          &:after {
            position: absolute;
            display: block;
            top: 4px;
            left: 7px;
            content: "";
            width: 6px;
            height: 10px;
            border-right: 2px solid transparent;
            border-bottom: 2px solid transparent;
            transform: rotate(45deg);
            transition: 200ms ease-in-out all;
          }
        }

        // Checked
        &:checked {
          & + span {
            &:before {
              background: $checkbox-selected;
              box-shadow: none;
            }
            &:after {
              border-color: white;
            }
          }
        }
      }

      .error {
        margin-top: 5px;
        padding-left: $checkbox-size + 10;
      }
    }

    &.labelinputs {
      position: relative;
      label {
        opacity: 0;
        position: absolute;
        top: -14px;
        left: 15px;
        font-size: 14px;
        color: #232f62;
        letter-spacing: 0;
        padding: 0px 5px;
        line-height: 30px;
        background: white;
        transition: 0.2s all ease-in-out;
      }

      input {
        &:focus {
          & + label {
            opacity: 1;
          }
        }

        &:not(:placeholder-shown) {
          & + label {
            opacity: 1;
          }

          &[aria-invalid="false"] {
            & + label {
              & + .error {
                &:before {
                  content: "\f00c";
                  font-family: "Font Awesome 5 Pro";
                  font-weight: 500;
                  font-size: 16px;
                  color: #099d49;
                  position: absolute;
                  right: 15px;
                  top: -43px;
                }
              }
            }
          }
        }

        &[aria-invalid="true"] {
          border-color: #f21e1e;
          & + label {
            color: #f21e1e;
            opacity: 1;

            & + .error {
              &:after {
                position: absolute;
                right: 15px;
                top: -43px;
                content: "\f06a";
                font-family: "Font Awesome 5 Pro";
                font-weight: 500;
                font-size: 16px;
                color: #f21e1e;
                letter-spacing: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
