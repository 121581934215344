<template>
    <div class="qr-code">
        <img class="qr" src="../../assets/QRCode.png" alt="QR">
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    }  
}
</script>

<style lang="scss">
    .qr-code{
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        left: 20px;
        @media only screen and (max-width: 992px) {
            margin-top: 50px;
            position: static;
            transform: none;
            text-align: center;
            // width: 100%;
        }

        @media only screen and (max-width: 580px) {
            img{
                width: 100%;
            }
        }
  }
</style>
