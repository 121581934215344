<template>
  <div class="wrapper" v-if="referer == 'registratie'">
    <img class="bg bg-1" src="../assets/bg-img1.png" alt="bg" />
    <img class="bg bg-2" src="../assets/bg-img.png" alt="bg" />

    <div class="container">
      <div class="row">
        <div class="logo">
          <img src="../assets/logo.png" alt="logo" />
        </div>
        <transition name="moveHeader">
          <Appheader v-if="getRoute == 'Home'" />
        </transition>
        <router-view></router-view>
        <app-cookie></app-cookie>
        <!-- <Appfooter/> -->
      </div>
    </div>
  </div>
  <div class="wrapper wrapper--orange" v-else>
    <!-- <img class="bg bg-1" src="../assets/bg-img1.png" alt="bg"> -->

    <div class="bg bg--full bg--desktop" src="" alt="bg"></div>

    <div class="container">
      <div class="row">
        <div class="logo">
          <img src="../assets/stamceldonatie-logo.png" alt="logo" />
        </div>
        <transition name="moveHeader">
          <Appheader v-if="getRoute == 'Home'" />
        </transition>
        <router-view></router-view>
        <app-cookie></app-cookie>
        <!-- <Appfooter/> -->
      </div>
    </div>
  </div>
</template>

<script>
import Appheader from "./layout/header.vue";
import AppCookie from "./atoms/Cookiebar.vue";
import { mapState } from "vuex";
import cookies from "vue-cookies";

export default {
  name: "App",
  components: {
    Appheader,
    AppCookie,
    // Appfooter
  },
  data() {
    return {
      firstpage: "",
    };
  },
  computed: {
    ...mapState("app", ["referer"]),
    getRoute: function () {
      if (window.innerWidth < 992 && this.referer != "registratie") {
        return this.$route.name;
      } else {
        return "Home";
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    require("./../assets/share.png");
    // init the app/portal settings
    var self = this;
    this.$store
      .dispatch("app/init")
      .then(() => {
        this.$store.commit("app/setUserLoading", false);
        // nothings it works, moving on..
      })
      .catch(() => {
        // On some OSX devices on Mobile and or Desktop Safari and Chrome Mobile
        // the page hangs due a random bug SNI 421 Misdirected Request
        // could be something with multiple request from the same domain
        setTimeout(function () {
          self.$store
            .dispatch("app/init")
            .then(() => {
              // worked the second time, temp fix for now...
            })
            .catch(() => {
              // still errors never mind..
            });
        }, 4000);
      });

    // Setup GA if we have cookies that allow this
    if (cookies.get("cookiebar") == "CookieAllowed" && this.$gtm) {
      this.$gtm.enable(true);
    }
    if (cookies.get("cookiebar") == "CookieDisallowed" && this.$gtm) {
      this.$gtm.enable(false);
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("../../node_modules/@fortawesome/fontawesome-pro/css/all.css");

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #1e2b62;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}

.no-scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  font-weight: normal;
}

h1 {
  font-size: 59px;
  line-height: 64px;
}

a {
  text-decoration: none;
}
img {
  display: block;
  //   width: 100%;
}
#app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 1240px;
  padding: 0px 20px;
  margin-right: auto;
  margin-left: auto;
}

.wrapper {
  position: relative;

  &--orange {
    background-color: white;

    .bg {
      overflow: hidden;
      &--full,
      img {
        width: 100%;
        height: 100%;
      }

      &--desktop {
        position: fixed;
        display: block !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../assets/matchisfondsbg.svg");
        @media only screen and (min-width: 992px) {
          display: block;

          background-image: url("../assets/matchisfondsbg.svg");
        }
      }
      &--mobile {
        display: none;
        @media only screen and (max-width: 992px) {
          display: block;
        }
      }
    }
    .logo {
      position: relative;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0;
      padding-bottom: 60px;

      @media only screen and (max-width: 992px) {
        margin-bottom: 20px;
      }
      &:after {
        content: "";
        position: absolute;
        background-size: 486px;
        background-repeat: no-repeat;
        background-position: 0px -35px;
        background-image: url("../assets/stamceldonatie-logo-bg.svg");
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @media only screen and (max-width: 992px) {
          background-position: center -123px;
          background-size: 760px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      img {
        position: relative;
      }
      img {
        left: 65px;
        max-width: 330px;
        z-index: 9;
        top: 13px;
        @media only screen and (max-width: 992px) {
          max-width: 250px;
          margin: 0 auto;
          left: 0;
          top: 20px;
        }
      }
    }

    .form .back-btn {
      color: #008ab8 !important;
      z-index: 999;
      top: -85px;
      font-size: 22px;
    }
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.logo {
  flex: 0 0 100%;
  padding-top: 50px;
  padding-left: 25px;
  z-index: 9;
}

.bg {
  position: absolute;
  width: 30vw;
}

.bg-2 {
  bottom: 0;
  left: 0;
}

.bg-1 {
  top: 0;
  left: 30%;
}

.hidden {
  display: none;
  visibility: hidden;
}

.moveHeader-enter-active,
.moveHeader-leave-active {
  transition: all 0.3s;
}

.moveHeader-enter,
    .moveHeader-leave-to

    /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  max-height: 0px;
}

@media only screen and (max-width: 992px) {
  header {
    overflow: hidden;
    // max-height: 565px;
  }

  .row {
    margin-right: -20px;
    margin-left: -20px;
  }

  .bg {
    display: none;
  }

  .logo {
    padding: 30px;
    text-align: center;
  }
}
</style>
