// import Vue from 'vue';
import userService from '../../services/user-service';


export default {
	namespaced: true,
	state: {
		locale: 'nl',
        notice: false,
		loadingStates: {
			user: false,
			app: false,
		},
		referer: '',
    },
    mutations: {
		setUserLoading : (state, payload) => state.loadingStates.user = payload,
		setAppLoading : (state, payload) => state.loadingStates.app = payload,
		setReferer : (state, payload) => state.referer = payload,
	},
	getters: {
        getReferer: state => { return state.referer}
    },
    actions: {
		init ({state}) {
			return userService.init(state.locale).then((response) => {
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error);
			});
		},
	}	
}
