const { createApp } = require('vue');
import App from './components/App.vue'
import router from './router'
import store from './store/index'

let app = createApp(App)
.use(router)
.use(store)

app.mount('#app');

