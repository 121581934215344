import { createStore } from 'vuex'
import app from './modules/app';
import form from './modules/form';
import createPersistedState from 'vuex-persistedstate'


const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
  modules: {
    app,
    form,
  },
  strict: debug,
  plugins: [
  createPersistedState({
    storage: window.sessionStorage,
    key: 'store',
    paths: ['app', 'form'],
  }),
  ],    
})

export default store;