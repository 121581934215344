import _ from 'lodash';

const vuexMixin = {
    methods:{
        fillDataFromStore(form, store) {
            for (var key in store) {
                // if array clonedeep the array
                if (Array.isArray(store[key])) {
                    form[key] = _.clone(store[key], true);
                } else if (store[key] instanceof(Object)) {
                    // if its object also deep clone it
                    form[key] = _.clone(store[key], true);
                } else {
                    form[key] = store[key];
                }
            }            
        }
    }
}
export default vuexMixin;
