import {HTTP} from './http-common';
import objectMerge from 'object-merge';

export default {
	init: function(locale) {
		return HTTP.get('portal/dpm/'+locale).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
    fetchAddress(address) {
		let zipcode = address.state.postalNumbers + address.state.postalLetters + "+" + address.state.houseNumber;
		return HTTP.get('address/complete/'+zipcode).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
	saveDonation(donation) {
		const donationState = donation.getters.getAll;
		const donationObj = objectMerge(donationState.donation, donationState.data, donationState.payment);
		
		return HTTP.post('forms/donation', donationObj).then((response) => {
			return response;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
}
