<template>
  <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
    <div v-if="referer == 'registratie'">
      <figure class="logo">
        <img src="../../assets/stamceldonatie-logo.png" alt="logo" />
      </figure>
      <h2>
        Wil jij een bijdrage leveren aan de kosten van jouw registratie of die
        van een volgende donor?
      </h2>
      <p class="intro">
        De matchkans met een patiënt is erg klein, dus hoe meer donoren hoe
        beter. Gelukkig melden steeds meer mensen zich als stamceldonor aan.
        Elke nieuwe registratie kost Matchis 35 euro. Ons budget is helaas
        beperkt, dus om iedereen te kunnen registreren vragen we een vrijwillige
        bijdrage. Je kunt zelf aangeven of en zo ja hoeveel je aan jouw
        inschrijving wilt bijdrage. Het is niet verplicht, maar het wordt zeer
        gewaardeerd!
      </p>
      <a
        class="fonds-link btn"
        href="https://fondsstamceldonatie.nl/"
        target="_blank"
        >Meer weten over Matchis Fonds Stamceldonatie?</a
      >
    </div>
    <div
      class="form-group montly"
      :class="{ error: errors.Option }"
      @change="changeAmountDefault()"
    >
      <legend></legend>
      <legend v-if="referer == 'registratie'">Hoe vaak wil je doneren?</legend>
      <legend v-else>Hoe vaak wil je doneren?</legend>
      <Field
        type="radio"
        name="frequency"
        id="once"
        value="once"
        v-model="frequency"
      />
      <label
        @click="chosenOption"
        class="montly__label"
        :class="{ active: frequency == 'once' }"
        for="once"
        >Eenmalig</label
      >
      <Field
        type="radio"
        name="frequency"
        id="recurring"
        value="recurring"
        v-model="frequency"
      />
      <label
        @click="chosenOption"
        class="montly__label"
        :class="{ active: frequency == 'recurring' }"
        for="recurring"
        >Maandelijks</label
      >
      <span class="error">{{ errors.frequency }}</span>
    </div>
    <div class="form-group donation__message">
      <p>Met een maandelijkse gift help je ons nóg meer!</p>
      <img src="../../assets/arrow.svg" alt="arrow" />
    </div>
    <div
      v-if="frequency == 'once'"
      class="form-group amount"
      :class="{ error: errors.amount || errors.amountOption }"
    >
      <span class="form-section" v-if="referer == 'registratie'"
        >Welk bedrag wil je doneren?</span
      >
      <span class="form-section" v-else>Welk bedrag wil je doneren?</span>

      <Field
        type="radio"
        id="amount-10"
        value="10"
        name="amountOption"
        v-model="amountOption"
        @click="donationChange($event.target)"
      />
      <label class="amount__label" for="amount-10">10</label>
      <Field
        type="radio"
        id="amount-25"
        value="25"
        name="amountOption"
        v-model="amountOption"
        @click="donationChange($event.target)"
      />
      <label class="amount__label" for="amount-25">25</label>
      <Field
        type="radio"
        id="amount-35"
        value="35"
        name="amountOption"
        v-model="amountOption"
        @click="donationChange($event.target)"
      />
      <label class="amount__label" for="amount-35">35</label>
      <span class="error" v-if="errors.amountOption">Selecteer een bedrag</span>
    </div>
    <div v-else-if="frequency == 'recurring'" class="form-group amount">
      <span class="form-section">Welk bedrag wil je doneren?</span>
      <Field
        type="radio"
        id="amount-5"
        value="5"
        name="amountOption"
        v-model="amountOption"
        @click="donationChange($event.target)"
      />
      <label class="amount__label" for="amount-5">5</label>
      <Field
        type="radio"
        id="amount-10"
        value="10"
        name="amountOption"
        v-model="amountOption"
        @click="donationChange($event.target)"
      />
      <label class="amount__label" for="amount-10">10</label>
      <Field
        type="radio"
        id="amount-25"
        value="25"
        name="amountOption"
        v-model="amountOption"
        @click="donationChange($event.target)"
      />
      <label class="amount__label" for="amount-25">25</label>
      <span class="error" v-if="errors.amountOption">Selecteer een bedrag</span>
    </div>

    <div
      class="form-group customAmount"
      :class="{ error: errors.amount || errors.amountCustom }"
    >
      <label for="donationAmountCustom">€</label>
      <Field
        name="amountCustom"
        type="text"
        class="form-control donation-amount"
        placeholder="anders"
        v-model="amountCustom"
        @click="donationCustomClick($event)"
      />
      <span class="error" v-if="errors.amount || errors.amountCustom"
        >Vul een eigen bedrag in.</span
      >
    </div>
    <div class="form-group">
      <Field type="text" class="hidden" name="amount" v-model="amount" />
      <button type="submit" class="btn btn-primary mr-1">Naar gegevens</button>
    </div>
  </Form>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { mapState } from "vuex";
import vuexMixin from "../../mixins/vuexMixin";

export default {
  name: "Home",
  mixins: [vuexMixin],
  components: {
    Form,
    Field,
  },
  data() {
    return {
      frequency: "",
      amountOption: "35",
      amountCustom: "",
    };
  },
  created() {
    vuexMixin.methods.fillDataFromStore(this, this.donation);
    if (this.amount == "") {
      this.amount = 35;
    } else {
      this.amountOption == this.amount;
    }
  },
  setup() {
    function emptyStringToNull(value, originalValue) {
      if (typeof originalValue === "string" && originalValue === "") {
        return null;
      }
      return value;
    }
    const schema = Yup.object().shape({
      frequency: Yup.string().required("Donatie optie is verplicht"),
      amountCustom: Yup.number()
        .positive()
        .transform((value, originalValue) => {
          if (typeof originalValue === "string") {
            return parseFloat(originalValue.replace(",", "."));
          }
          return value;
        })
        .transform(emptyStringToNull)
        .nullable(),
      amount: Yup.number()
        .transform((value, originalValue) => {
          if (typeof originalValue === "string") {
            return parseFloat(originalValue.replace(",", "."));
          }
          return value;
        })
        .required()
        .positive(),
    });
    return {
      schema,
    };
  },

  mounted() {
    if (window.location.toString().includes("type=once")) {
      document.querySelector("#once").click();
      const urlAmount = window.location.search.split("?type=once&amount=")[1];
      if (urlAmount === "35") {
        this.amount = 35;
        this.amountOption = 35;
        setTimeout(() => {
          document.getElementById("amount-35").checked = true;
        }, 200);
      } else if (urlAmount === "25") {
        this.amount = 25;
        this.amountOption = 25;
        setTimeout(() => {
          document.getElementById("amount-25").checked = true;
        }, 200);
      } else if (urlAmount === "10") {
        this.amount = 10;
        this.amountOption = 10;
        setTimeout(() => {
          document.getElementById("amount-10").checked = true;
        }, 200);
      } else {
        setTimeout(() => {
          this.amount = urlAmount;
          this.amountOption = urlAmount;
          document.querySelector('input[name="amountCustom"]').value =
            urlAmount;
          document.querySelector('input[name="amountCustom"]').click;
        }, 200);
      }
    }

    if (window.location.toString().includes("type=monthly")) {
      document.querySelector("#recurring").click();
      const urlAmount = window.location.search.split(
        "?type=monthly&amount="
      )[1];
      if (urlAmount === "25") {
        this.amount = 25;
        this.amountOption = 25;
        setTimeout(() => {
          document.getElementById("amount-25").checked = true;
        }, 200);
      } else if (urlAmount === "10") {
        this.amount = 10;
        this.amountOption = 10;
        setTimeout(() => {
          document.getElementById("amount-10").checked = true;
        }, 200);
      } else if (urlAmount === "5") {
        this.amount = 5;
        this.amountOption = 5;
        setTimeout(() => {
          document.getElementById("amount-5").checked = true;
        }, 200);
      } else {
        setTimeout(() => {
          this.amount = urlAmount;
          this.amountOption = urlAmount;
          document.querySelector('input[name="amountCustom"]').value =
            urlAmount;
          document.querySelector('input[name="amountCustom"]').click;
        }, 200);
      }
    }
  },
  methods: {
    onSubmit: function (values) {
      // When donating monthly you are forced to give a bank authorization.
      if (this.frequency == "recurring") {
        this.$store.commit("form/setMethod", {
          method: "authorization",
        });
      }
      this.$store.commit("form/setDonation", values);
      this.$router.push({ name: "Data" });
    },
    donationChange: function (e) {
      this.amount = e.value;
      this.amountCustom = "";
    },
    changeAmountDefault: function () {
      if (this.frequency == "once") {
        document.getElementById("amount-35").checked = true;
        this.amount = 35;
      } else {
        document.getElementById("amount-25").checked = true;
        this.amount = 25;
      }
    },
    donationCustomClick: function (e) {
      this.amount = e.target.value;
      this.amountOption = null;
    },
    chosenOption: function (e) {
      e.target.classList.add("active");
      var sameLabels = e.target.classList[0];
      var count = document.querySelectorAll(`.${sameLabels}.active`);
      if (count.length > 1) {
        count.forEach((element) => {
          element.classList.remove("active");
        });
        e.target.classList.add("active");
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapState("form", ["donation"]),
    ...mapState("app", ["referer"]),
  },
  watch: {
    amountCustom: function (val) {
      if (val) {
        this.amountCustom = val.replace(".", ",");
        this.amount = val.replace(",", ".");
      } else {
        this.amountCustom = val
        this.amount = val
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  color: #232f62;
  margin-bottom: 20px;
  font-weight: 900;
}
.logo {
  padding: 0;
  margin-bottom: 35px;
  img {
    max-width: 330px;
    margin: 0 auto;
  }
}
.intro {
  margin-bottom: 30px;
  font-weight: 300;
  line-height: 1.75em;
}
.fonds-link {
  padding: 0;
  background: transparent;
  margin: 30px 0px;
  display: block;
  text-align: left;
  text-decoration: underline;
  color: #f39200;
  font-size: 16px;
}
.form {
  .montly {
    margin-bottom: 0;
    position: relative;

    &:before {
      content: "";
      background: #f2f3f6;
      position: absolute;
      left: 50%;
      bottom: 5px;
      width: 70px;
      z-index: 0;
      height: 62px;
      transform: translateX(-50%);
    }

    label {
      background: #f2f3f6;
      border-radius: 30.5px;
      border: 0px;
      box-shadow: none;
      color: #697286;
      z-index: 1;
      position: relative;

      &.active {
        background: #ffffff;
        border: 1px solid #8890cd;
        box-shadow: 0 2px 10px 0 rgba(35, 47, 98, 0.14);
        border-radius: 30.5px;
        text-align: center;
        font-size: 19px;
        padding: 15px 20px;
        color: #232f62;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        display: inline-block;
        flex: 1;
        z-index: 2;
      }
    }
  }

  input[type="text"] {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
  }

  .amount {
    label {
      margin-right: 20px;
      margin-bottom: 20px;
      position: relative;

      &:last-of-type {
        margin-right: 0px;
      }
    }

    input:checked {
      & + label {
        &:before {
          content: "\f058";
          font-family: "Font Awesome 5 Pro";
          font-weight: 600;
          font-size: 16px;
          color: #099d49;
          position: absolute;
          right: 3px;
          top: -10px;
          z-index: 1;
          background: white;
        }
      }
    }
  }
  .donation__message {
    color: #008ab8;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px auto;
    max-width: 250px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    @media only screen and (max-width: 992px) {
      font-size: 15px;
      max-width: 200px;
    }
    img {
      position: absolute;
      right: -19px;
      top: -1px;
      bottom: 0;
      width: 44px;
      @media only screen and (max-width: 992px) {
        width: 28px;
      }
    }
  }
  .donation-amount {
    & + .error {
      bottom: -30px;
    }
  }

  @media only screen and (max-width: 992px) {
    form {
      min-height: calc(100vh - 478px);
    }

    legend {
      font-size: 22px;
    }
  }

  .customAmount {
    position: relative;

    input {
      padding-left: 50px;
    }

    label {
      position: absolute;
      top: 15px;
      left: 25px;
      font-size: 19px;
      color: #232f62;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
}
</style>
