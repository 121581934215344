import userService from '../../services/user-service';

export default {
    namespaced: true,
    state : { 
        donation: {
          frequency: 'once',
          amount: '',
          amountCustom: '',
        },
        data: {
            firstname: "",
            lastname: "",
            email: "",
            postalNumbers: "",
            postalLetters: "",
            houseNumber: "",
            street: "",
            city: "",
        },
        payment: {
            method: "ideal",
            iban: "",
            idealBank: "",
            accountHolder: "",
        }
    },
    mutations: {
        setDonation : (state, payload) => state.donation = payload,
        // Data
        setData : (state, payload) => state.data = payload,
        setPostal : (state, payload) => {
            state.postalNumbers = payload.postalNumbers;
            state.postalLetters = payload.postalLetters;
            state.houseNumber = payload.houseNumber;
        },
        setStreet : (state, payload) => {
            state.data.street = payload.street;
            state.data.city = payload.city;
        },
        // Payment
        setPayment : (state, payload) => state.payment = payload,
        setMethod : (state, payload) => {
            state.payment.method = payload.method;
        },

        resetDonation : (state) => {
            state.donation = {
                frequency: 'once',
                amount: '',
                amountCustom: '',
              };
            state.data = {
                firstname: "",
                lastname: "",
                email: "",
                postalNumbers: "",
                postalLetters: "",
                houseNumber: "",
                street: "",
                city: "",
            };
            state.payment = {
                method: "ideal",
                iban: "",
                idealBank: "",
                accountHolder: "",
            }
        }
    },
    getters: {
        getAll: state => { return state}
    },
    actions: {
        fetchAddress (address) {
            return userService.fetchAddress(address).then((response) => {
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error);
			});
        },
        saveDonation (donation) {
            return userService.saveDonation(donation).then((response) => {

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			});
        },
    },
}
