<template>
    <a class="btn" href="#">
        <slot>Verzenden</slot>
    </a>
</template>

<script>

export default {
  name: 'Button',
}
</script>

<style lang="scss" scoped>
  a, .btn{
    border-radius: 30.5px;
    font-size: 21px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 30px;
    padding: 16px 40px;
    display: inline-block;
    width: auto;

    &:hover{

    }
  }
</style>
