<template>
  <header>
    <div v-if="referer == 'registratie'">
      <h1>Bedankt voor je aanmelding als stamceldonor!</h1>
      <p>
        Je ontvangt een mail van ons met een bevestigingslink (check ook je
        spam). Vergeet niet op deze link te klikken, pas dan kunnen wij je
        registratie verder verwerken. Mocht je na een uur nog geen mail
        ontvangen hebben, neem dan contact op via het
        <a href="https://www.matchis.nl/contact/" target="_blank" rel="nofollow"
          >contactformulier.</a
        >
      </p>
      <Button target="_blank" href="https://www.matchis.nl/over-stamceldonatie"
        >Meer weten? <i class="fas fa-external-link"></i
      ></Button>
    </div>
    <div v-else>
      <h1>€ 35 = één levenskans</h1>
      <p>
        Voor mensen met bloedkanker is een stamceltransplantatie vaak de laatste
        kans op genezing. De kosten van registratie en typering voor een donor
        zijn &euro; 35,-. Steun ons met een financiële bijdrage, elke euro
        helpt. Met jouw steun kunnen wij zorgen voor nog meer stamceldonoren.
      </p>
      <Button target="_blank" href="https://fondsstamceldonatie.nl/"
        >Meer weten over Matchis Fonds Stamceldonatie?
        <i class="fas fa-external-link"></i
      ></Button>
    </div>
  </header>
</template>

<script>
import Button from "../atoms/Button";
import { mapState } from "vuex";

export default {
  name: "appHeader",
  components: {
    Button,
  },
  computed: {
    ...mapState("app", ["referer"]),
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
header {
  flex: 0 0 50%;
  position: relative;
  width: 100%;
  padding-right: 90px;
  padding-left: 40px;
  display: block;
  margin-top: 195px;
  overflow: initial;
}

h1 {
  margin-bottom: 20px;
  font-weight: 600;
}

p {
  color: #fff;
  font-size: 26px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 37px;
  margin-bottom: 20px;
  font-weight: 300;

  @media only screen and (max-width: 992px) {
    line-height: 26px;
    font-size: 18px;
  }
  a {
    color: white;
    text-decoration: underline;
  }
}

.btn {
  background: #232f62;
  padding: 0;
  background: transparent;
  margin: 30px 0px;
  display: block;
  text-align: left;
  text-decoration: underline;
  i {
    text-decoration: none;
    margin-left: 10px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 992px) {
  header {
    flex: 0 0 100%;
    margin-top: 5px;
    max-width: 615px;
    margin: 0 auto;
    padding: 0px 30px;

    h1 {
      font-size: 38px;
      line-height: 42px;
    }
    .btn {
      margin-top: 15px;
      margin-bottom: 50px;
      line-height: 26px;
      font-size: 18px;
    }
  }
}
</style>
