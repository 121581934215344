<template>
  <div class="form-wrapper" :class="getLink">
    <div class="form">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Form",
  methods: {
    fillDataFromStore(store) {
      for (var key in store) {
        // if array clonedeep the array
        if (Array.isArray(store[key])) {
          this[key] = _.clone(store[key], true);
        } else if (store[key] instanceof Object) {
          // if its object also deep clone it
          this[key] = _.clone(store[key], true);
        } else {
          this[key] = store[key];
        }
      }
    }
  },
  computed: {
    getLink: function() {
      return this.$route.path === "/share" ? "share" : "";
    }
  }
};
</script>

<style lang="scss">
.form-wrapper {
  flex: 0 0 50%;
  position: relative;
  width: 100%;
  margin-top: 60px;
  display: block;
  max-width: 615px;

  &.share {
    .form {
      background: transparent;
    }
  }
}

.form {
  background-color: #fff;
  border-radius: 31.5px 31.5px 0px 0px;
  padding: 5px;
  padding: 50px 60px;
  padding-top: 40px;
  min-height: calc(100vh - 169px);

  .data {
    opacity: 1;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    .error {
      flex: 0 0 100%;
      color: #f21e1e;
      font-weight: 400;
      font-size: 16px;
      margin-top: -15px;
      margin-bottom: 20px;
      position: relative;
    }
  }

  .back-btn {
    font-size: 19px;
    color: #697286;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 10px;
    display: block;
    i {
      margin-right: 5px;
    }
  }

  legend,
  .form-section {
    font-size: 34px;
    color: #232f62;
    letter-spacing: 0;
    line-height: 50px;
    margin-bottom: 15px;
    display: block;
    flex: 0 0 100%;
  }

  input[type="text"],
  input[type="email"] {
    border: 1px solid #8890cd;
    font-size: 19px;
    color: #232f62;
    letter-spacing: 0;
    line-height: 30px;
    padding: 14px 20px;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(35, 47, 98, 0.14);
    border-radius: 6px;
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    &::placeholder {
      opacity: 0.35;
      color: #232f62;
    }
  }

  input[type="radio"] {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    width: 0px;
    & + label {
      background: #ffffff;
      border: 1px solid #8890cd;
      box-shadow: 0 2px 10px 0 rgba(35, 47, 98, 0.14);
      border-radius: 30.5px;
      text-align: center;
      font-size: 19px;
      padding: 15px 20px;
      color: #232f62;
      letter-spacing: 0;
      text-align: center;
      line-height: 30px;
      display: inline-block;
      flex: 1;
      cursor: pointer;
    }

    &:focus {
      & + label {
        outline: 2px auto -webkit-focus-ring-color;
      }
    }
  }
}

.error {
  color: red;
  display: block;
}

.btn {
  margin-top: 40px;
  border-radius: 30.5px;
  font-size: 21px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 30px;
  padding: 16px 40px;
  display: block;
  background: #f39200;
  border: 0px;
  width: 100%;
  box-shadow: none;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  opacity: 1;
  transition: 0.3s all ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media only screen and (max-width: 992px) {
  .form-wrapper {
    flex: 0 0 100%;
    margin: 0 auto;
    min-height: auto;
  }

  .form {
    padding: 35px;

    .legend,
    .form-section {
      font-size: 22px;
      line-height: initial;
    }
    .back-btn {
      margin-bottom: 0px;
      position: absolute;
      top: -65px;
      left: 20px;
      color: white !important;
      font-size: 28px;
      span {
        display: none;
      }
    }
  }
}
</style>
