<template>
  <div class="thanks">
    <figure>
      <img src="../../assets/thanks.png" alt="thanks">
    </figure>
    <h1>Er is iets misgegaan!</h1>
    <router-link class="btn" :to="{ name: 'Home' }" tag="li">Opnieuw proberen</router-link>

  </div>
</template>

<script>
import { mapState } from 'vuex';
  
export default {
  name: 'Thanks',
  computed: {
    ...mapState('form',[
      'donation',
      'payment',
      'data',
    ]),
    twitterShareURL () {
      let shareUrl = window.location.origin;
      let shareTitle = 'Doneer geld en geef een kans op leven'
      return `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareTitle)}&url=${encodeURIComponent(shareUrl)}`;
    },
    facebookShareUrl() {
      let shareUrl = window.location.origin;
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`
    }
  }
};
</script>

<style lang="scss" scoped>
.thanks{
  figure{
    width: 100%;
    text-align: center;
  }
  h1{
    font-size: 34px;
    color: #232F62;
    letter-spacing: 0;
    line-height: 50px;
    margin-bottom: 10px;
  }
  strong{
    font-size: 26px;
    color: #232F62;
    letter-spacing: 0;
    margin-bottom: 10px;
    display: block;
    span{
      color: #099D49;
    }
  }
  p{
    font-weight: 400;
    font-size: 20px;
    color: #232F62;
    letter-spacing: 0;
    margin-bottom: 30px;
  }

  a{
    margin-bottom: 10px;
    margin-top: 0px;
    color: white;
    text-align: center;
    line-height: 30px;
    font-size: 21px;
    &:first-of-type{
      background: #434086;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        margin-left: 5px;
      }
    }
    &:nth-of-type(2), &:nth-of-type(3){
      background: #232F62;
    }
    &:last-child{
      background: white;
      border: 2px solid #232F62;
      color: #232F62;
    }
  }
}

</style>
