import axios from 'axios';
// import axiosRetry from 'axios-retry';

export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    timeout: 15000
});

// retry one more time when first request fails on GET, HEAD, OPTIONS, PUT or DELETE
// https://www.npmjs.com/package/axios-retry

// add interceptors
HTTP.interceptors.response.use(function (response) {
	// success
	return response;
}, function(error) {
	if (error.response) {
		// The request was made and the server responded with a status code
        // that falls out of the range of 2xx
		console.log(error.response);
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
	} else {
        // Something happened in setting up the request that triggered an Error
		console.log('Error', error.message);
	}
	console.log(error.config);
	return Promise.reject(error);
});
