<template>
  <div class="thanks">
    <figure>
      <img src="../../assets/thanks.png" alt="thanks" />
    </figure>
    <h1>Bedankt!</h1>
    <strong
      >Je hebt <span v-if="amount">€{{ amount }}</span> gedoneerd</strong
    >
    <p>
      Bedankt voor jouw donatie. Zonder donateurs als jij zijn wij nergens!<br />
      In de mail vind je een bevestiging van je donatie.
    </p>

    <a
      class="btn"
      target="_blank"
      rel="nofollow noreferrer"
      :href="facebookShareUrl"
      >Delen op Facebook</a
    >
    <a
      class="btn"
      target="_blank"
      rel="nofollow noreferrer"
      :href="twitterShareURL"
      >Delen op Twitter</a
    >
    <a class="btn" href="https://www.matchis.nl/">Ga naar matchis.nl</a>
  </div>
</template>

<script>
export default {
  name: "RegThanks",
  data() {
    return {
      amount: null,
    };
  },
  mounted() {
    const current_url = window.location;
    var url = new URL(current_url);
    var amount = url.searchParams.get("amount");
    if (amount) {
      this.amount = atob(amount);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'donatie_voltooid',
        'donation_amount': this.amount,
      });
    }
  },
  computed: {
    twitterShareURL() {
      let shareUrl = window.location.origin;
      let shareTitle = "Doneer geld en geef een kans op leven";
      return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        shareTitle
      )}&url=${encodeURIComponent(shareUrl)}`;
    },
    facebookShareUrl() {
      let shareUrl = window.location.origin;
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.thanks {
  figure {
    width: 136px;
    text-align: center;
    margin: 30px auto 30px;
  }
  h1 {
    font-size: 34px;
    color: #333333;
    letter-spacing: 0;
    line-height: 50px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  strong {
    font-size: 26px;
    color: #333333;
    letter-spacing: 0;
    margin-bottom: 10px;
    display: block;
    span {
    //   color: #099d49;
    }
  }
  p {
    font-weight: 400;
    font-size: 20px;
    color: #333333;
    letter-spacing: 0;
    margin-bottom: 30px;
  }

  a {
    margin-bottom: 10px;
    margin-top: 0px;
    color: white;
    text-align: center;
    line-height: 30px;
    font-size: 21px;
    &:first-of-type {
      background: #434086;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-left: 5px;
      }
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      background: #008abb;
    }
    &:last-child {
      background: white;
      border: 2px solid #232f62;
      color: #232f62;
    }
  }
}
</style>
