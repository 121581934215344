<template>
  <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
    <router-link class="back-btn" :to="{ name: 'Data' }" tag="li"
      ><i class="far fa-arrow-left"></i><span>Terug</span></router-link
    >
    <div class="form-group" :class="{ montly: donation.frequency == 'once' }">
      <legend>Betaling</legend>
      <template v-if="donation.frequency == 'once'">
        <span v-if="referer == 'registratie'" class="subtitle"
          >Hoe wil je betalen?</span
        >
        <span v-else class="subtitle">Hoe wil je betalen?</span>
        <Field
          type="radio"
          id="ideal"
          value="ideal"
          name="method"
          v-model="method"
        />
        <label
          @click="chosenOption"
          for="ideal"
          :class="{ active: selectedMethod == 'ideal', montly__label: true }"
          >iDEAL</label
        >
        <Field
          type="radio"
          id="authorization"
          name="method"
          value="authorization"
          v-model="method"
        />
        <label
          @click="chosenOption"
          for="authorization"
          :class="{
            active: selectedMethod == 'authorization',
            montly__label: true,
          }"
          >Machtiging</label
        >
      </template>
      <template v-else>
        <span class="subtitle">Bankmachtiging</span>
        <Field
          type="radio"
          id="authorization"
          name="method"
          value="authorization"
          v-model="method"
        />
      </template>
    </div>
    <div
      class="form-group"
      v-if="selectedMethod == 'ideal'"
      :class="{ error: errors.idealBank }"
    >
      <span class="subtitle">Kies uw bank</span>
      <div class="bank" v-for="bank in IdealBanks" :key="bank.key">
        <Field
          type="radio"
          :id="bank.key"
          name="idealBank"
          :value="bank.key"
          v-model="idealBank"
        />
        <label :for="bank.key"
          ><img :src="bank.img" /><span>{{ bank.title }}</span></label
        >
      </div>
      <span class="error" v-if="errors.idealBank && submitClicked"
        >Selecteer een bank</span
      >
    </div>
    <template v-else-if="selectedMethod == 'authorization'">
      <div class="form-group labelinputs" :class="{ error: errors.iban }">
        <Field
          type="text"
          name="iban"
          v-model="iban"
          value=""
          placeholder="Uw IBAN"
        />
        <label for="iban">Uw IBAN</label>
      </div>
      <div
        class="form-group labelinputs"
        :class="{ error: errors.accountHolder }"
      >
        <Field
          type="text"
          name="accountHolder"
          v-model="accountHolder"
          value=""
          placeholder="Naam rekeninghouder"
        />
        <label for="accountHolder">Naam rekeninghouder</label>
      </div>
    </template>

    <!-- <div class="form-group checkbox-group">
      <Field type="checkbox" name="newsletter" id="newsletter" />
      <label for="newsletter">Graag word ik op de hoogte gehouden over het werk van Matchis</label>
    </div> -->
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-primary mr-1"
        :disabled="true == loadingStates.user"
        @click="submitClicked = true"
      >
        Ja, ik doneer {{ paymentText }} €{{ donation.amount }}
      </button>
    </div>
    <div class="loader" v-if="loadingStates.user">
      <div class="lds-circle"><div></div></div>
    </div>
  </Form>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { mapState } from "vuex";
import vuexMixin from "../../mixins/vuexMixin";

export default {
  name: "Payment",
  mixins: [vuexMixin],
  components: {
    Form,
    Field,
  },
  created() {
    vuexMixin.methods.fillDataFromStore(this, this.payment);
    this.selectedMethod = this.method;
    this.$store.commit("app/setUserLoading", false);
  },
  setup() {
    function mod97(string) {
      var checksum = string.slice(0, 2),
        fragment;
      for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum;
    }
    Yup.addMethod(Yup.string, "iban", function (args) {
      return this.test("test-iban", args, function (input) {
        var CODE_LENGTHS = {
          AD: 24,
          AE: 23,
          AT: 20,
          AZ: 28,
          BA: 20,
          BE: 16,
          BG: 22,
          BH: 22,
          BR: 29,
          CH: 21,
          CY: 28,
          CZ: 24,
          DE: 22,
          DK: 18,
          DO: 28,
          EE: 20,
          ES: 24,
          FI: 18,
          FO: 18,
          FR: 27,
          GB: 22,
          GI: 23,
          GL: 18,
          GR: 27,
          GT: 28,
          HR: 21,
          HU: 28,
          IE: 22,
          IL: 23,
          IS: 26,
          IT: 27,
          JO: 30,
          KW: 30,
          KZ: 20,
          LB: 28,
          LI: 21,
          LT: 20,
          LU: 20,
          LV: 21,
          MC: 27,
          MD: 24,
          ME: 22,
          MK: 19,
          MR: 27,
          MT: 31,
          MU: 30,
          NL: 18,
          NO: 15,
          PK: 24,
          PL: 28,
          PS: 29,
          PT: 25,
          QA: 29,
          RO: 24,
          RS: 22,
          SA: 24,
          SE: 24,
          SI: 19,
          SK: 24,
          SM: 27,
          TN: 24,
          TR: 26,
          AL: 28,
          BY: 28,
          CR: 22,
          EG: 29,
          GE: 22,
          IQ: 23,
          LC: 32,
          SC: 31,
          ST: 25,
          SV: 28,
          TL: 23,
          UA: 29,
          VA: 22,
          VG: 24,
          XK: 20,
        };
        var iban = String(input)
            .toUpperCase()
            .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
          code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
          digits;
        // check syntax and length
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
          return false;
        }
        // rearrange country code and check digits, and convert chars to ints
        digits = (code[3] + code[1] + code[2]).replace(
          /[A-Z]/g,
          function (letter) {
            return letter.charCodeAt(0) - 55;
          }
        );
        // final check
        return mod97(digits) === 1;
      });
    });
    const schema = Yup.object().shape({
      method: Yup.string(),
      idealBank: Yup.string().when("method", {
        is: "ideal",
        then: Yup.string().required("Veld is verplicht."),
      }),
      iban: Yup.string().when("method", {
        is: "authorization",
        then: Yup.string().iban().required("Optie is verplicht"),
      }),
      accountHolder: Yup.string().when("method", {
        is: "authorization",
        then: Yup.string().min(3).required("Optie is verplicht"),
      }),
    });
    return {
      schema,
    };
  },
  data() {
    return {
      selectedMethod: null,
      submitClicked: false,
      iban: null,
      accountholder: null,
      idealBank: null,
      method: "ideal",

      // Default values should come from store
      IdealBanks: [
        {
          key: "abn",
          title: "ABN AMRO",
          img: require("../../assets/abn.png"),
        },
        {
          key: "asn",
          title: "ASN Bank",
          img: require("../../assets/asn.png"),
        },
        {
          key: "ing",
          title: "ING",
          img: require("../../assets/ing.png"),
        },
        {
          key: "rabo",
          title: "Rabobank",
          img: require("../../assets/rabo.png"),
        },
        {
          key: "sns",
          title: "SNS bank",
          img: require("../../assets/sns.png"),
        },
        {
          key: "regiobank",
          title: "Regiobank",
          img: require("../../assets/regio.png"),
        },
        {
          key: "triodos",
          title: "Triodos bank",
          img: require("../../assets/triodos.png"),
        },
        {
          key: "lanschot",
          title: "Van Lanschot",
          img: require("../../assets/lanschot.png"),
        },
        {
          key: "knab",
          title: "Knab bank",
          img: require("../../assets/knab.png"),
        },
        {
          key: "bunq",
          title: "Bunq bank",
          img: require("../../assets/bunq.png"),
        },
      ],
    };
  },
  methods: {
    onSubmit: function (values) {
      this.$store.commit("app/setUserLoading", true);
      this.$store.commit("form/setPayment", values);

      this.$store
        .dispatch("form/saveDonation")
        .then((response) => {
          this.$store.commit("form/resetDonation");

          if (response.success === true) {
            if (response.redirectUrl.startsWith("http")) {
              window.location = response.redirectUrl;
            } else {
              this.$router.push(response.redirectUrl);
            }
          } else {
            this.$router.push("/mislukt");
          }
        })
        .catch((error) => {
          this.$store.commit("form/resetDonation");
          this.$router.push("/mislukt");
          console.info(error);
        });
    },
    chosenOption: function (e) {
      let clickedMethod = e.target.getAttribute("for");
      this.selectedMethod = clickedMethod;
      this.iban = null;
      this.accountHolder = null;
      this.idealBank = null;
      this.$store.commit("form/setMethod", {
        method: clickedMethod,
      });
    },
  },
  computed: {
    ...mapState("form", ["donation", "payment"]),
    ...mapState("app", ["loadingStates"]),
    ...mapState("app", ["referer"]),
    paymentText() {
      return this.donation.frequency == "once" ? "eenmalig" : "maandelijks";
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  .back-btn {
    font-size: 19px;
    color: #697286;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 10px;
    i {
      margin-right: 5px;
    }
  }

  .subtitle {
    flex: 0 0 100%;
    font-size: 22px;
    color: #232f62;
    letter-spacing: 0;
    display: block;
    margin-bottom: 10px;
  }

  .btn {
    background-color: #099d49;
  }

  .form-group {
    margin-bottom: 20px;
    .error {
      &:after,
      &:before {
        top: -23px !important;
      }
    }
  }

  .checkbox-group {
    margin-top: 40px;

    label {
      font-weight: 500;
      font-size: 18px;
      color: #232f62;
      letter-spacing: 0;
      line-height: 19px;
      padding-left: 50px;
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        background: #ffffff;
        border: 1px solid #8890cd;
        box-shadow: 0 2px 10px 0 rgba(35, 47, 98, 0.14);
        border-radius: 6px;
        height: 28px;
        width: 28px;
        position: absolute;
        left: 0px;
        top: 0px;
      }

      &:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-weight: 500;
        font-size: 16px;
        color: #099d49;
        position: absolute;
        left: 6px;
        top: 5px;
        display: none;
      }
    }

    input {
      display: none;
      &:checked {
        & + label {
          &:after {
            display: block;
          }
        }
      }
    }
  }

  .montly {
    margin-bottom: 30px;
    position: relative;

    &:before {
      content: "";
      background: #f2f3f6;
      position: absolute;
      left: 50%;
      bottom: 0px;
      width: 70px;
      z-index: 0;
      height: 62px;
      transform: translateX(-50%);
    }

    label {
      background: #f2f3f6;
      border-radius: 30.5px;
      border: 0px;
      box-shadow: none;
      color: #697286;
      z-index: 1;
      position: relative;

      &.active {
        background: #ffffff;
        border: 1px solid #8890cd;
        box-shadow: 0 2px 10px 0 rgba(35, 47, 98, 0.14);
        border-radius: 30.5px;
        text-align: center;
        font-size: 19px;
        padding: 15px 20px;
        color: #232f62;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        display: inline-block;
        flex: 1;
        z-index: 2;
      }
    }
  }

  .bank {
    flex: 0 0 50%;

    &:nth-child(even) {
      padding-right: 10px;
    }

    &:nth-child(odd) {
      padding-left: 10px;
    }

    @media only screen and (max-width: 992px) {
      flex: 0 0 100%;
      padding: 0px !important;
    }
    label {
      opacity: 0.3;
      width: 100%;
      border: 0px;
      box-shadow: none;
      text-align: left;
      border-bottom: 1px solid rgba(35, 47, 98, 0.11);
      border-radius: 0px;
      padding: 15px 0px;
      position: relative;
      display: flex;
      align-items: center;
      img {
        margin-right: 20px;
      }
    }

    input:checked {
      & + label {
        opacity: 1;
        &:after {
          content: "\f058";
          font-family: "Font Awesome 5 Pro";
          font-weight: 600;
          font-size: 16px;
          color: #099d49;
          position: absolute;
          right: 5px;
          top: 16px;
        }
      }
    }
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .lds-circle {
    display: inline-block;
    transform: translateZ(1px);
    opacity: 0.7;
  }
  .lds-circle > div {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    background: #232f62;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  @keyframes lds-circle {
    0%,
    100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(180deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
}
</style>
